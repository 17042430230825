.cart-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    position: relative;
  }
  
  .back-button {
    position: absolute;
    top: 1rem;
    left: 1rem;
    background: none;
    border: none;
    cursor: pointer;
    color: #333;
    transition: color 0.3s ease;
  }
  
  .back-button:hover {
    color: #ff6b6b;
  }
  
  .cart-title {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    color: #2d3436;
  }
  
  .cart-items {
    list-style-type: none;
    padding: 0;
  }
  
  .cart-item {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .cart-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
  
  .cart-item-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 1rem;
  }
  
  .cart-item-details {
    flex-grow: 1;
  }
  
  .cart-item-details h3 {
    margin: 0 0 0.5rem 0;
    font-size: 1.2rem;
    color: #2d3436;
  }
  
  .cart-item-details p {
    margin: 0;
    color: #4CAF50;
  }
  
  .cart-item-quantity {
    display: flex;
    align-items: center;
  }
  
  .cart-item-quantity button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    color: #2d3436;
    transition: color 0.3s ease;
  }
  
  .cart-item-quantity button:hover {
    color: #ff6b6b;
  }
  
  .cart-item-quantity span {
    margin: 0 0.5rem;
    font-weight: bold;
  }
  
  .checkout-section {
    margin-top: 2rem;
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .total {
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .checkout-button {
    width: 100%;
    padding: 1rem;
    background-color: #ff6b6b;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .checkout-button:hover {
    background-color: #ff5252;
  }
  
  .empty-cart {
    text-align: center;
    padding: 2rem;
    background-color: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .empty-cart svg {
    color: #b2bec3;
    margin-bottom: 1rem;
  }
  
  .empty-cart p {
    font-size: 1.2rem;
    color: #636e72;
    margin-bottom: 1rem;
  }
  
  .continue-shopping {
    display: inline-block;
    padding: 0.8rem 1.5rem;
    background-color: #ff6b6b;
    color: white;
    text-decoration: none;
    border-radius: 8px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .continue-shopping:hover {
    background-color: #ff5252;
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    text-align: center;
    max-width: 80%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .modal-icons {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 1.5rem 0;
  }
  .modal-content h2 {
    margin-top: 1rem;
  }

  .close-modal-button {
    background-color: #FF6600;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 1rem;
  }
  .close-modal-button:hover {
    background-color: #e55d00;
  }