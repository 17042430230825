/* Gallery.css */
.gallery-container {
    width: 100%;
    /* max-width: 1200px; */
    margin: 0 auto;
    padding: 20px;
}

.gallery-header {
    position: relative;
    height: 300px;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
                url('https://res.cloudinary.com/dpcx2zugw/image/upload/v1731650680/ximqkydbfcafhnzftiil.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}

.gallery-header h1 {
    color: white;
    font-size: 48px;
    font-weight: 300;
    letter-spacing: 2px;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 20px;
    padding: 20px 0;
}

.gallery-item {
    position: relative;
    height: 300px;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.gallery-item:hover {
    transform: translateY(-5px);
}

.gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.gallery-item:hover img {
    transform: scale(1.05);
}

.gallery-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
    padding: 20px;
    color: white;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.gallery-item:hover .overlay {
    opacity: 1;
}

.gallery-overlay span {
    font-size: 18px;
    font-weight: 500;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .gallery-grid {
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
    
    .gallery-header h1 {
        font-size: 36px;
    }
}

@media (max-width: 480px) {
    .gallery-grid {
        grid-template-columns: 1fr;
    }
    
    .gallery-header {
        height: 200px;
    }
    
    .gallery-header h1 {
        font-size: 28px;
    }
    
    .gallery-container {
        padding: 10px;
    }
}