/* Navbar.css */
.navbar {
  position: fixed;
  width: 100%;
  transition: all 0.3s;
  z-index: 60;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

.navbar.scrolled {
  background-color: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.15);
}

.navbar-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
}

/* Logo styles */
.logo-container {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  width: 50px;
  height: 50px; 
}

.app-logo{
  width: 50px;
  height: 50px;
  object-fit: contain;
  display: block; 
}

/* Desktop Navigation */
.desktop-nav {
  display: none;
}

/* Added media query for mobile logo size */
@media (max-width: 767px) {
  .logo-container {
    width: 100px;
    height: 50px;
  }
}

@media (min-width: 768px) {
  .desktop-nav {
    display: flex;
    gap: 1.5rem;
  }
}

.nav-item {
  position: relative;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #000;
  transition: color 0.3s;
  background: none;
  border: none;
  cursor: pointer;
}

.nav-item:hover {
  color: #FF6B35;
}

.nav-item::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #4CAF50;
  transform: scaleX(0);
  transition: transform 0.3s;
}

.nav-item:hover::before,
.nav-item.active::before {
  transform: scaleX(1);
}

.nav-item.active {
  color: #4CAF50;
}

/* Mobile Menu Button */
.mobile-menu-button {
  display: block;
}

@media (min-width: 768px) {
  .mobile-menu-button {
    display: none;
  }
}

.hamburger-button {
  padding: 0.5rem;
  border-radius: 0.375rem;
  color: #4B5563;
  transition: color 0.3s;
  background: none;
  border: none;
  cursor: pointer;
}

.hamburger-button:hover {
  color: #FF6B35;
}

.icon {
  width: 1rem;
  height: 1rem;
}

/* Mobile Navigation */
.mobile-nav {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.mobile-nav.open {
  max-height: 16rem;
  opacity: 1;
  pointer-events: auto;
}

.mobile-nav-content {
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.mobile-nav-item {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  text-align: left;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 0.375rem;
  transition: all 0.3s;
  background: none;
  border: none;
  cursor: pointer;
  color: #4B5563;
}

.mobile-nav-item:hover {
  color: #FF6B35;
  background-color: rgba(255, 107, 53, 0.1);
}

.mobile-nav-item.active {
  color: #4CAF50;
  background-color: rgba(76, 175, 80, 0.1);
}

@media (min-width: 768px) {
  .mobile-nav {
    display: none;
  }
}