.not-found-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  padding: 20px;
}

.not-found-content {
  text-align: center;
  max-width: 500px;
  padding: 40px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.not-found-content h1 {
  font-size: 120px;
  margin: 0;
  color: #dc3545;
  line-height: 1;
}

.not-found-content h2 {
  font-size: 30px;
  margin: 20px 0;
  color: #343a40;
}

.not-found-content p {
  font-size: 18px;
  color: #6c757d;
  margin-bottom: 30px;
}

.home-button {
  display: inline-block;
  padding: 12px 30px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s;
}

.home-button:hover {
  background-color: #0056b3;
}
