.pricing-container {
    min-height: 100vh;
    background-color: #f9fafb;
    padding: 5rem 1rem;
}

.pricing-content {
    max-width: 1200px;
    margin: 0 auto;
}

.pricing-header-section {
    text-align: center;
    margin-bottom: 3rem;
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
}

.pricing-header-section h2 {
    font-size: 2.25rem;
    font-weight: bold;
    color: #111827;
    margin-bottom: 1rem;
}

.pricing-header-section p {
    font-size: 1.125rem;
    color: #6b7280;
    line-height: 1.5;
}

.pricing-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.pricing-card {
    background: white;
    border-radius: 0.5rem;
    border: 1px solid #e5e7eb;
    padding: 1.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    height: 100%;
}

.pricing-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.pricing-card.popular {
    border: 2px solid #22c55e;
}

.popular-badge {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.popular-badge span {
    background-color: #22c55e;
    color: white;
    padding: 0.25rem 1rem;
    border-radius: 9999px;
    font-size: 0.875rem;
    white-space: nowrap;
}

.pricing-header {
    text-align: center;
    margin-bottom: 1.5rem;
}

.pricing-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #111827;
    margin-bottom: 0.5rem;
}

.pricing-price {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
}

.price {
    font-size: 2rem;
    font-weight: bold;
    color: #111827;
}

.period {
    color: #6b7280;
}

.feature-list {
    list-style: none;
    padding: 0;
    margin: 0 0 1.5rem 0;
    flex-grow: 1;
}

.feature-item {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    margin-bottom: 1rem;
    color: #4b5563;
    line-height: 1.4;
}

.checkmark {
    width: 1.25rem;
    height: 1.25rem;
    color: #22c55e;
    flex-shrink: 0;
    margin-top: 0.125rem;
}

.get-started-btn {
    width: 100%;
    padding: 0.5rem 1rem;
    background-color: #ff6b35;
    color: white;
    border: none;
    border-radius: 0.375rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
    margin-bottom: 15px;
}

.get-started-btn:hover {
    background-color: #ff5a1f;
}

/* Media Queries */
@media (max-width: 1024px) {
    .pricing-cards {
        grid-template-columns: repeat(2, 1fr);
        max-width: 800px;
    }
}

@media (max-width: 768px) {
    .pricing-cards {
        grid-template-columns: 1fr;
        max-width: 450px;
        /* padding-bottom: 20px; */
    }
    .pricing-card{
        padding-bottom: 1px;
    }
    .pricing-header-section h2 {
        font-size: 1.875rem;
        padding-top: 28px;
    }

    .pricing-header-section p {
        font-size: 1rem;
    }

    .pricing-container {
        padding: 2rem 1rem;
    }
}