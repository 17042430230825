/* Contact Section Styles */
.contact-section {
  padding: 80px 0;
  /* margin-top: 60px;  */
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

.contact-main-heading {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #333;
}

.form-container {
  max-width: 1000px;
  margin: 0 auto;
}

.contact-form {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.contact-form h4 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
}

.form-fields {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
}

.input-group label {
  margin-bottom: 5px;
  font-weight: 500;
  color: #333;
}

.input-group input {
  width: 100%;
  height: 40px;
  /* padding: 8px 12px; */
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  font-size: 14px;
}

.input-group textarea {
  width: 100%;
  /* padding: 12px; */
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  resize: vertical;
  min-height: 120px;
  font-size: 14px;
}

.send-btn {
  background-color: #ff6600;
  color: #fff;
  padding: 10px;
  width: 250px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.send-btn:hover {
  background-color: #e65c00;
}

.error {
  border-color: #ff0000 !important;
}

.error-text {
  color: #ff0000;
  font-size: 12px;
  margin-top: 4px;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .main-heading {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .form-fields {
    flex-direction: column;
    gap: 0;
  }

  .contact-form {
    padding: 30px;
  }

  .contact-form h4 {
    font-size: 1.2rem;
    margin-bottom: 15px;
  }
}