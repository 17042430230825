@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

:root {
  --primary-color: #FF6600;
  --secondary-color: #4CAF50;
  --background-color: #F5F5F5;
  --text-color: #333333;
  --veg-color: #4CAF50;
  --non-veg-color: #FF0000;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

.place-order {
  max-width: 800px;
  margin: 0 auto;
  padding: 10px;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 16px 20px; */
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid var(--primary-color);
  margin-bottom: 30px;
}

.logo {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-left: 12px;
}

h1 {
  color: var(--primary-color);
  margin: 0;
  font-size: 1.5rem;
  flex-grow: 1;
  text-align: center;
  /* text-align: left; */
  /* padding-left: 8px; */
}

.cart-icon {
  position: relative;
  cursor: pointer;
  padding: 10px;
}

.cart-count {
  position: absolute;
  top: -1px;
  right: -1px;
  background-color: var(--primary-color);
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
}

.menu-items {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 10px;
}

.menu-item {
  background-color: white;
  border-radius: 8px;
  /* padding:10px; */
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  height: 150px;
  /* align-items: stretch; */
}

.item-image-container {
  flex: 0 0 165px;
}

.item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  margin-left: 10px;
}

.item-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  margin-left: 10px;
}

.item-details {
  flex: 1;
  text-align: left;
}

.item-name-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}

.veg-indicator {
  width: 14px;
  height: 12px;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
}

.veg-indicator.veg {
  border-color: var(--veg-color);
}

.veg-indicator.non-veg {
  border-color: var(--non-veg-color);
}

.veg-indicator-inner {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.veg-indicator.veg .veg-indicator-inner {
  background-color: var(--veg-color);
}

.veg-indicator.non-veg .veg-indicator-inner {
  background-color: var(--non-veg-color);
}

.item-details h3 {
  margin: 0;
  font-size: 0.8rem;
}

.description, .availability {
  padding-left: 9px;
  margin: 8px 0;
  font-size: 0.6rem;
}
.price{
  padding-left: 9px;
  margin: 8px 0;
  font-size: 1.rem;
}

.price {
  font-weight: bold;
  color: #4CAF50;
}

.item-actions {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.add-to-cart, .quantity-control button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 0.9rem;
}

.add-to-cart:hover, .quantity-control button:hover {
  background-color: #e65c00;
}

.quantity-control {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid var(--primary-color);
}

.quantity-control button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.quantity-control span {
  font-weight: bold;
}

.quantity-control button:hover {
  background-color: #e65c00;
}

.quantity-control span {
  font-weight: 500;
  min-width: 24px;
  text-align: center;
}

/* Category Styles */
.menu-categories {
  max-width: 2000px;
  margin: 0 auto;
  padding: 10px;
}

.menu-category {
  margin-bottom: 10px;
  border: 1.6px solid #ffcc66;
  border-radius: 8px;
  overflow: hidden;
}

.category-header {
  width: 100%;
  padding: 12px 16px;
  background-color:  #ffcc66;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s;
}

.category-header:hover {
  background-color: #ffcc66;
}

.category-header h2 {
  margin: 0;
  font-size: 0.9rem;
  color: #333;
}

.category-items {
  padding: 10px;
  background-color: white;
}
.menu-item {
  margin-bottom: 20px;
  padding: 0px;
  border: 1px solid #eee;
  border-radius: 8px;
  background-color: white;
}

.menu-item.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.menu-item.disabled .item-actions {
  display: none;
}

.menu-item.disabled .item-details {
  pointer-events: none;
}
/* Animation for dropdown */
.category-items {
  animation: slideDown 0.3s ease-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 600px) {
  .place-order {
    padding: 2px;
  }

  header {
    /* padding: 5px 0; */
    /* margin:10px; */
    padding-top:30px;
    padding-bottom: 22px;
    padding-left: 10px;
    padding-right: 25px;
  }

  h1 {
    font-size: 1.6rem;
  }

  .logo {
    width: 28%; 
    height: 18%;
    margin-right: 5px;
    border-radius: 10px;
    /* padding-bottom: -20px; */
  }

  .menu-item {
    flex-direction: row;
  }

  .item-image-container {
    height: auto;
    width: 140px;
    margin-right: 5px;
  }

  .item-content {
    padding: 8px;
  }

  .item-details h3 {
    font-size: 0.9rem;
  }

  .description, .availability {
    font-size: 0.7rem;
  }

  .price{
    font-size: 0.8rem;
  }

  .add-to-cart, .quantity-control button {
    padding: 6px 10px;
    font-size: 0.8rem;
  }

  .quantity-control button {
    width: 30px; 
    height: 30px; 
    font-weight: bold;
  }
}

/* Menu Footer Styles */
.menu-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.5rem 1rem;
  background-color: #f9fafb;
  margin-top: auto;
}

.menu-footer-title {
  font-size: 2rem;
  font-weight: 600;
  color: #4b5563;
  margin-bottom: 1rem;
  text-align: center;
}

.menu-footer-subtitle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #666;
  font-size: 1.1rem;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  line-height: 1.5;
}

.heart-icon {
  color: #ec4899;
  font-size: 1.5rem;
}

/* Heart Animation */
@keyframes heartbeat {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.heart-animate {
  display: inline-block;
  animation: heartbeat 2s infinite;
  animation-delay: 1s;
}

@media (max-width: 600px) {
  .menu-footer {
    padding: 2.2rem 1.9rem;
  }

  .menu-footer-title {
    font-size: 1.75rem;
  }

  .menu-footer-subtitle {
    font-size: 1rem;
    padding: 0 1rem;
  }
}

/* Cart Notification Styles */
.cart-notification {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  z-index: 50;
}

.cart-notification-content {
  background-color: #10b981;
  color: white;
  border-radius: 9999px;
  padding: 1rem 1.5rem;
  margin: 0 auto;
  max-width: 28rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.cart-notification-text {
  font-size: 1.125rem;
  font-weight: 600;
}

.cart-view-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
}

.cart-arrow-icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.5rem;
}

/* Animation Classes */
.slide-up-enter {
  transform: translateY(100%);
}

.slide-up-enter-active {
  transform: translateY(0);
  transition: transform 0.3s ease-out;
}

.slide-up-exit {
  transform: translateY(0);
}

.slide-up-exit-active {
  transform: translateY(100%);
  transition: transform 0.3s ease-in;
}
.search-container {
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 8px;
  /* padding: 8px 12px; */
  width: 100%;
  /* max-width: 300px; */
  margin: 0 auto;
  border: 0.8px solid #333;
}

.search-input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
  background-color: transparent;
  color: #333;
}

.search-icon {
  display: flex;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
}

.microphone-icon {
  margin-left: 8px;
  cursor: pointer;
}

.search-icon svg,
.microphone-icon svg {
  fill: none;
  stroke: #e65c00;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

@media (max-width: 600px) {
  .search-container {
    max-width: 360px;
    margin: 10px;
  }

  .search-input {
    font-size: 1.1rem;
  }

  .search-icon {
    margin-left: 8px;
  }
}

/* Filter Options Styles */
.filter-options {
  display: flex;
  gap: 12px;
  padding: 16px;
  background-color: transparent;
  border-bottom: 1px solid #eee;
}

.filter-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: 0.8px solid #333;
  border-radius: 20px;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.2s ease;
}

.filter-btn:hover {
  background-color: #f5f5f5;
}

.filter-btn.active {
  background-color: #f0f7ff;
  border-color: #0066cc;
  color: #0066cc;
}

.filter-text {
  font-size: 14px;
  font-weight: 500;
}

/* Veg/Non-veg Indicators */
.indicator {
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 4px;
  position: relative;
}

.indicator::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  border-radius: 2px;
}

.indicator.veg {
  border-color: #0f8a0f;
}

.indicator.veg::after {
  background-color: #0f8a0f;
}

.indicator.non-veg {
  border-color: #e43b3b;
}

.indicator.non-veg::after {
  background-color: #e43b3b;
}

/* Update existing search container styles */
.search-container {
  position: sticky;
  top: 0;
  z-index: 10;
  /* background-color: white; */
  /* border: 0.8px solid #333; */
  padding: 7px;
}

.collapse-toggle-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 16px;
  border: 1px solid #333;
  border-radius: 20px;
  background-color: 'transparent';
  cursor: pointer;
  transition: all 0.2s ease;
}

.collapse-toggle-btn:hover {
  background-color: 'transparent';
}

.chevron-icon {
  width: 16px;
  height: 16px;
  transition: transform 0.2s ease;
}
/* Optional: Add these styles if you want to center the text with the icon */
.collapse-toggle-btn span {
  line-height: 1;
  font-weight: bold;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .filter-options {
    padding: 12px;
    margin-bottom: 10px;
    gap: 8px;
  }

  .filter-btn {
    padding: 6px 9px;
  }

  .filter-text {
    font-size: 13px;
  }
}

/* Out of Stock Badge */
.out-of-stock-badge {
  background-color: #f44336;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
}

/* Menu Not Updated Container */
.menu-not-updated-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.menu-not-updated {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #555;
}

.smile-icon {
  font-size: 36px;
  margin-top: 16px;
}

.no-items-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #666;
}

.frowning-icon {
  font-size: 12rem;
  margin-top: 1rem;
}

.no-search-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #666;
}

.thinking-icon {
  font-size: 2rem;
  margin-top: 1rem;
}