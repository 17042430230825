/* Footer.css */
.footer {
  background: linear-gradient(to right, #1a1f2c, #2d3748);
  color: #e2e8f0;
  padding: 4rem 0 2rem;
}

.footer-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 2rem;
}

.footer-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
}

/* Section Styles */
.footer-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.section-title {
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 700;
  position: relative;
  margin-bottom: 1rem;
}

/* .section-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -0.5rem;
  width: 4rem;
  height: 0.25rem;
  background-color: #3b82f6;
  border-radius: 0.125rem;
} */

/* About Section */
.about-text {
  color: #cbd5e1;
  line-height: 1.6;
}

/* Quick Links */
.quick-links {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.quick-links a {
  color: #cbd5e1;
  text-decoration: none;
  transition: all 0.3s ease;
  display: inline-block;
}

.quick-links a:hover {
  color: #3b82f6;
  transform: translateX(0.5rem);
}

/* Contact Info */
.contact-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #cbd5e1;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-item:hover {
  color: #3b82f6;
}

.contact-icon {
  color: #3b82f6;
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
}

/* Social Links */
.social-links {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.social-icon {
  background-color: #3b82f6;
  color: white;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  text-decoration: none;
}

.social-icon:hover {
  background-color: #2563eb;
  transform: translateY(-0.25rem);
}

/* Copyright Section */
.copyright {
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid #4a5568;
  text-align: center;
  color: #94a3b8;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .footer-container {
    padding: 0 1.5rem;
  }
}

@media (max-width: 768px) {
  .footer-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .footer {
    padding: 3rem 0 1.5rem;
  }

  .section-title {
    font-size: 1.25rem;
  }

  .social-links {
    justify-content: center;
  }
}

/* Additional Hover Effects */
.contact-item, .social-icon {
  position: relative;
  overflow: hidden;
}

.contact-item:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #3b82f6;
  transition: width 0.3s ease;
}

.contact-item:hover:after {
  width: 100%;
}

/* Focus States for Accessibility */
a:focus-visible {
  outline: 2px solid #3b82f6;
  outline-offset: 2px;
  border-radius: 0.25rem;
}