/* AboutUs.css */
.about-container {
    width: 100%;
    /* max-width: px; */
    margin: 0 auto;
  }
  
  .header-section {
    position: relative;
    height: 300px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('https://res.cloudinary.com/dpcx2zugw/image/upload/v1731650680/ximqkydbfcafhnzftiil.jpg');
    background-size: cover;
    background-position: center;
    margin-bottom: 50px;
  }
  
  .header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding-left: 50px;
  }
  
  .header-overlay h1 {
    color: white;
    font-size: 48px;
    font-weight: 500;
  }
  
  .content-section {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 0 20px;
  }
  
  .text-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .text-content p {
    margin-bottom: 20px;
    line-height: 1.6;
    color: #333;
    font-size: 16px;
  }
  
  .image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    margin-top: 30px;
  }
  
  .image-box {
    width: 100%;
    height: 300px;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .image-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .image-box img:hover {
    transform: scale(1.05);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .header-section {
      height: 200px;
    }
  
    .header-overlay h1 {
      font-size: 36px;
    }
  
    .content-section {
      gap: 20px;
    }
  
    .image-box {
      height: 250px;
    }
  }
  
  @media (max-width: 480px) {
    .header-overlay {
      padding-left: 20px;
    }
  
    .header-overlay h1 {
      font-size: 28px;
    }
  
    .text-content p {
      font-size: 14px;
    }
  
    .image-box {
      height: 200px;
    }
  }