/* Home.css */
.home-container {
    position: relative;
    min-height: 50vh;
  }
  
  .banner-section {
    position: relative;
    height: 600px;
    width: 100%;
    overflow: hidden;
  }
  
  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
  
  .overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .content-container {
    text-align: center;
    padding: 0 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .main-heading {
    font-size: 48px;
    font-weight: bold;
    color: white;
    margin-bottom: 20px;
  }
  
  .slogan {
    font-size: 24px;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 48px;
  }
  
  /* Floating Contact Buttons */
  .floating-buttons {
    position: fixed;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 16px;
    z-index: 50;
  }
  
  .contact-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 17px;
    border-radius: 50px;
    color: white;
    text-decoration: none;
    transition: all 0.3s ease;
    width: 25px;
    overflow: hidden;
  }
  
  .contact-button:hover {
    width: 128px;
  }
  
  .call-button {
    background-color: #2563eb;
  }
  
  .call-button:hover {
    background-color: #1d4ed8;
  }
  
  .whatsapp-button {
    background-color: #16a34a;
  }
  
  .whatsapp-button:hover {
    background-color: #15803d;
  }
  
  .button-text {
    position: absolute;
    right: 48px;
    opacity: 0;
    transition: opacity 0.3s ease;
    white-space: nowrap;
    padding-right: 8px;
  }
  
  .contact-button:hover .button-text {
    opacity: 1;
  }
  
  .button-icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .main-heading {
      font-size: 36px;
    }
  
    .slogan {
      font-size: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .main-heading {
      font-size: 28px;
    }
  
    .slogan {
      font-size: 18px;
      margin-bottom: 32px;
    }
  }