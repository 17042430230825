/* Clients.css */
.clients-container {
    /* max-width: 1200px; */
    margin: 0 auto;
    /* padding: 40px 20px; */
    /* padding-top: 20px; */
    background-color: #f8f9fa;
}

.clients-title {
    text-align: center;
    color: #1f2937;
    font-size: 2.5rem;
    font-weight: 700;
    padding-top: 80px;
    margin-bottom: 30px;
}

.clients-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 30px;
    justify-items: center;
}

.client-card {
    background: white;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 250px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.client-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.client-logo-container {
    width: 120px;
    height: 120px;
    margin: 0 auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.client-logo {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.client-name {
    color: #1f2937;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 10px 0;
}

.client-location {
    color: #6b7280;
    font-size: 0.9rem;
}

/* Media Queries */
@media (max-width: 768px) {
    .clients-grid {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 20px;
    }

    .clients-title {
        font-size: 2rem;
        padding-top: 70px;
        margin-bottom: 30px;
    }

    .client-card {
        padding: 15px;
    }

    .logo-container {
        width: 100px;
        height: 100px;
    }
}

@media (max-width: 480px) {
    .clients-container {
        padding: 20px 10px;
    }

    .clients-grid {
        grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
        gap: 15px;
    }

    .client-name {
        font-size: 1rem;
    }

    .client-location {
        font-size: 0.8rem;
    }
}